import http from "../http_common";

class CategoryService {
    getCategories() {
        return http.get(`/categories/`);
    }

    getMenuCategories = (params) => {
        return http.get(`/products/filter/products`, { params });
    };

}

export default new CategoryService();