import React from "react";
import { Modal } from "react-bootstrap";
import SignUpSignInForm from "./SignUpSignInForm"; // Import the form component

const LoginModal = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} size="lg" centered dialogClassName="custom-modal">
            <Modal.Body>
                <SignUpSignInForm /> {/* Render the form component inside the modal */}
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;
