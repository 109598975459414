import React, { PureComponent } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, Text } from 'recharts';

// Custom Tooltip Component
const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
        const { total_estimated_organic_clicks, estimated_monthly_organic_value, venture_name, venture_logo } = payload[0].payload;
        return (
            <div style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <img src={venture_logo} alt="" style={{ width: '50px', height: 'auto', marginRight: '10px' }} />
                    <p className="clr-theme-primary font600" style={{ margin: 0 }}>{venture_name}</p>
                </div>
                <p>{`Monthly Web traffic: ${total_estimated_organic_clicks}`}</p>
            </div>
        );
    }
    return null;
};

// Custom Star Shape Component
const StarShape = (props) => {
    const { cx, cy, fill } = props;
    const size = 25; // size of the star

    return (
        <svg
            x={cx - size / 2}
            y={cy - size / 2}
            width={size}
            height={size}
            viewBox="0 0 1024 1024"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M512 770.667l-228.267 135.467 60.373-256-184.106-161.067 248.32-20.693 103.68-237.227 103.68 237.227 248.32 20.693-184.106 161.067 60.373 256z" />
        </svg>
    );
};

export default class SiteTrafficChart extends PureComponent {
    render() {
        const { data, loading } = this.props;

        // Check if data is available
        if (loading) {
            return <div className="text-center text-white">Loading...</div>;
        }

        const relativeMarketShareValues = data.map(d => d.relativeMarketShare);
        const growthRateValues = data.map(d => d.growthRate);
        const minRelativeMarketShare = Math.min(...relativeMarketShareValues);
        const maxRelativeMarketShare = Math.max(...relativeMarketShareValues);
        const minGrowthRate = Math.min(...growthRateValues);
        const maxGrowthRate = Math.max(...growthRateValues);

        const centerX = (minRelativeMarketShare + maxRelativeMarketShare) / 2;
        const centerY = (minGrowthRate + maxGrowthRate) / 2;

        return (
            <div style={{ border: '2px solid green', borderRadius: '20px', padding: '0px' }}>
                <ResponsiveContainer width="100%" height={400}>
                    <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                        <XAxis
                            type="number"
                            dataKey="relativeMarketShare"
                            name="Customer Engagement"
                            domain={['dataMin', 'dataMax']}
                            hide
                        />
                        <YAxis
                            type="number"
                            dataKey="growthRate"
                            name="Growth Potential"
                            domain={['dataMin', 'dataMax']}
                            hide
                        />

                        <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />

                        {/* Scatter plot with custom star shape */}
                        <Scatter
                            name="Data"
                            data={data}
                            shape={<StarShape />}
                            fill="#86E900"
                        />

                        {/* Reference lines for the center */}
                        <ReferenceLine x={centerX} stroke="green" strokeWidth={2} />
                        <ReferenceLine y={centerY} stroke="green" strokeWidth={2} />

                        {/* Quadrant Labels */}
                        <ReferenceLine
                            x={minRelativeMarketShare}
                            y={maxGrowthRate}
                            label={<Text x={5} y={30} fill="#fff">Emerging Innovators</Text>}
                            stroke="transparent"
                        />
                        <ReferenceLine
                            x={maxRelativeMarketShare}
                            y={maxGrowthRate}
                            label={<Text x={290} y={30} fill="#fff">Top Performers</Text>}
                            stroke="transparent"
                        />
                        <ReferenceLine
                            x={minRelativeMarketShare}
                            y={minGrowthRate}
                            label={<Text x={5} y={350} fill="#fff">New & Niche</Text>}
                            stroke="transparent"
                        />
                        <ReferenceLine
                            x={maxRelativeMarketShare}
                            y={minGrowthRate}
                            label={<Text x={270} y={350} fill="#fff">Popular Specialists</Text>}
                            stroke="transparent"
                        />
                    </ScatterChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
