import React, { useState, useEffect, useRef } from "react";
import LoginModal from "../Views/Auth/LoginModal";
import { useLocation } from "react-router-dom";
import Category_services from "../Services/category_services";
import Search from "../Views/Products/Components/Search";
import SiteTrafficChart from "../Views/Products/Components/CategoryProductMatrix";

const Header = () => {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [hoveredSubCategory, setHoveredSubCategory] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar toggle state
    const sidebarRef = useRef(null); // Reference to the sidebar
    const location = useLocation();
    const [siteTrafficData, setSiteTrafficData] = useState([]); // State for site traffic data
    const [loading, setLoading] = useState(true); // Loading state for SiteTrafficChart

    const handleShow = () => setShowLoginModal(true);
    const handleClose = () => setShowLoginModal(false);
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Detect screen size change
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        // Close sidebar if clicked outside
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsSidebarOpen(false);
            }
        };

        // Fetch categories data
        const fetchCategories = async () => {
            try {
                const response = await Category_services.getCategories();
                setCategories(response.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        // Handle screen resize to detect mobile view
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Load site traffic data on mount
        const storedSiteTrafficData = localStorage.getItem('siteTrafficData');
        if (storedSiteTrafficData) {
            setSiteTrafficData(JSON.parse(storedSiteTrafficData));
            setLoading(false);
        }

        // Listen for localStorage changes
        const handleStorageChange = (event) => {
            if (event.key === 'siteTrafficData') {
                setSiteTrafficData(JSON.parse(event.newValue));
                setLoading(false);
            }
        };

        // Add event listeners
        if (isSidebarOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        window.addEventListener("resize", handleResize);
        window.addEventListener('storage', handleStorageChange);

        // Fetch categories initially
        fetchCategories();

        // Cleanup listeners on unmount or when dependencies change
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("resize", handleResize);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [isSidebarOpen]);


    return (
        <header id="header" className="header d-flex align-items-center fixed-top bg-black mobile-head-transparent">
            <div className="container-fluid container-xl position-relative d-flex flex-column flex-md-row align-items-center">
                {/* First row for mobile (Logo and Toggle Button) */}
                <div className="row w-100 d-md-none justify-content-between align-items-center mb-2 mobile-menu-first-row-height">
                    <div className="col-6">
                        <a href="/" className="logo d-flex align-items-center me-auto">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="Logo" />
                        </a>
                    </div>
                    <div className="col-6 text-end">
                        <button className="mobile-menu-toggle" onClick={toggleSidebar}>
                            <i className="fa fa-bars"></i>
                        </button>
                    </div>
                </div>

                {/* Second row for mobile (Search Box) */}
                <div className="row w-100 d-md-none justify-content-center">
                    <div className="col-8">
                        {/* Show the search component only on mobile, regardless of page */}
                        {isMobile && (
                            <Search width="100%" searchBtnRight="0" isMobile={true} isDesktop={false} />
                        )}
                    </div>
                    <div className="col-4">
                        <button className="btn btn-md btn-theme-default font30 mt-2" onClick={toggleSidebar}>View Grid</button>
                    </div>
                </div>

                {/* Desktop view layout */}
                <div className="d-none d-md-flex w-100 justify-content-between align-items-center">
                    <div className="col-md-2">
                        <a href="/" className="logo d-flex align-items-center me-auto">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="Logo" />
                        </a>
                    </div>

                    <div className="col-md-4">
                        {location.pathname !== "/" && (
                            <Search width="20em" searchBtnRight="30%" isMobile={false} isDesktop={true}/>
                        )}
                    </div>

                    <div className="col-md-4">
                        <ul className="exo-menu">
                            {/*<li><a href="/"><i className="fa fa-home"></i> Home</a></li>*/}
                            <li className="mega-drop-down">
                                <a href="#"><i className="fa fa-list"></i> Categories</a>
                                <div
                                    className="animated fadeIn mega-menu"
                                    onMouseEnter={() => setHoveredCategory(hoveredCategory)}  // Keeps hoveredCategory state active
                                    onMouseLeave={() => { setHoveredCategory(null); setHoveredSubCategory(null); }}  // Reset when mouse leaves the whole menu
                                >
                                    <div className="mega-menu-wrap">
                                        <div className="row">
                                            {/* Categories Column */}
                                            <div className="col-md-4">
                                                <h4 className="row mega-title">Categories</h4>
                                                <ul className="description">
                                                    {categories.map(category => (
                                                        <li key={category.id}
                                                            onMouseEnter={() => setHoveredCategory(category)}
                                                        >
                                                            <a href={`/category/${category.id}/products`}>
                                                                {category.name}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            {/* Subcategories Column - Only show if category is hovered */}
                                            {hoveredCategory && (
                                                <div className="col-md-4">
                                                    <h4 className="row mega-title">Sub Categories</h4>
                                                    <ul className="description">
                                                        {hoveredCategory.subcategories.map(subcategory => (
                                                            <li key={subcategory.id}
                                                                onMouseEnter={() => setHoveredSubCategory(subcategory)}
                                                            >
                                                                <a href={`/sub_category/${subcategory.id}/products`}>
                                                                    {subcategory.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                            {hoveredSubCategory && (
                                                <div className="col-md-4">
                                                    <h4 className="row mega-title">Topics</h4>
                                                    <ul className="description">
                                                        {hoveredSubCategory.topics.map(topic => (
                                                            <li key={topic.id}>
                                                                <a href={`/topic/${topic.id}/products`}>
                                                                    {topic.topic_name.charAt(0).toUpperCase() + topic.topic_name.slice(1).toLowerCase()}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li><a href="#contact-us"><i className="fa fa-phone"></i> Contact</a></li>
                        </ul>
                    </div>

                    <div className="col-md-2 d-none d-md-flex">
                        <button className="btn btn-theme-default btn-sm clr-white login-btn" onClick={handleShow}>
                            Login / Signup
                        </button>
                    </div>

                    {/* Mobile Sidebar */}
                    <div ref={sidebarRef} className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                        <button className="close-btn" onClick={toggleSidebar}>
                            <i className="fa fa-times"></i>
                        </button>
                        <div className="sidebar-content">
                            {/* Sidebar content */}
                        </div>
                    </div>
                </div>
            </div>


            {/* Sidebar for mobile menu */}
            <div ref={sidebarRef} className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
                <button className="close-btn" onClick={toggleSidebar}>
                    <i className="fa fa-times"></i>
            </button>
            <div className="sidebar-content">
                <h4 className="clr-white">Categories</h4>
                <ul>
                    {categories.map(category => (
                        <li key={category.id} onClick={() => setHoveredCategory(category)}>
                            {category.name}
                            {/* Subcategories */}
                            {hoveredCategory === category && (
                                <ul className="subcategories">
                                    {category.subcategories.map(subcategory => (
                                        <a href={`/sub_category/${subcategory.id}/products`}> <li key={subcategory.id}>{subcategory.name}</li></a>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
                <hr />
                <a href="#" className="sidebar-link">Contact</a>
                <button className="btn btn-theme-default btn-sm clr-white login-btn mt-3" onClick={handleShow}>
                    Login / Signup
                </button>

                <div className="pt-3">
                    {location.pathname !== "/" && (
                        <>
                            <h3 className="clr-white">Ecomatrix</h3>
                            <SiteTrafficChart data={siteTrafficData} loading={loading} />
                        </>
                    )}
                </div>
            </div>
        </div>

    <LoginModal show={showLoginModal} handleClose={handleClose} />
</header>
);
};

export default Header;

