import axios from 'axios';

// const userEmail = localStorage.getItem('email');
export default axios.create({
    // baseURL: "http://localhost:8000/api/v1",
    baseURL: "https://api.ecosearch.ai/api/v1",
    headers: {
        "Access-Control-Allow-Headers" : "Content-Type",
        "Access-Control-Allow-Origin": "https://ixh7ft1nmh.execute-api.us-east-1.amazonaws.com/prod",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PUT",
        "Content-type": "application/json",
    }
});