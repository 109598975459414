import http from "../http_common";

class ProductService {
    searchAutocompleteProduct(searchKeyWord) {
        return http.get(`/products/autocomplete/search?search_term=${searchKeyWord}`);
    }

    searchProducts(searchKeyWord) {
        return http.get(`/products/search?search_term=${searchKeyWord}`);
    }

    getProductById(id) {
        return http.get(`/products/${id}`);
    }

    getAlternativeProducts = (params) => {
        return http.get(`/products/alternative/`, { params });
    };

    getCalculatedFormulaSiteTraffic = (params) => {
        return http.get(`/products/calculate/site_traffic`, { params });
    };

}

export default new ProductService();