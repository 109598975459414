import { lazy } from 'react';

const Home = lazy(() => import("../src/Views/index"));
const SearchProducts = lazy(() => import("../src/Views/Products/SearchProducts"));
const CategoryProducts = lazy(() => import("../src/Views/Products/CategoryProducts"));
const ProductDetail = lazy(() => import("../src/Views/Products/ProductDetail"));
const Traffic = lazy(() => import("../src/Views/Traffic"));
const Dashboard = lazy(() => import("./Views/User/Dashboard"));
const Profile = lazy(() => import("./Views/User/Pages/Profile"));
const ChangePassword = lazy(() => import("./Views/User/Pages/ChangePassword"));

export const Routes = [
    {
        path: "/",
        component: Home,
        exact: true
    },
    {
        path: "/products/search",
        component: SearchProducts,
        exact: true
    },
    {
        path: "/category/:categoryId/products",
        component: CategoryProducts,
        exact: true
    },
    {
        path: "/sub_category/:subCategoryId/products",
        component: CategoryProducts,
        exact: true
    },
    {
        path: "/topic/:topicId/products",
        component: CategoryProducts,
        exact: true
    },
    {
        path: "/product/:id/detail",
        component: ProductDetail,
        exact: true
    },
    {
        path: "/traffic",
        component: Traffic,
        exact: true
    },
    {
        path: "/user/dashboard",
        component: Dashboard,
        exact: true
    },
    {
        path: "/user/profile",
        component: Profile,
        exact: true
    },
    {
        path: "/user/change_password",
        component: ChangePassword,
        exact: true
    }
];
