import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { Link } from 'react-router-dom';
import Product_services from "../../../Services/product_services";
import swal from "sweetalert2";

const Search = ({ width = "40em", searchBtnRight = "5%", isMobile = false, isDesktop = false}) => {  // Default right padding to 2% if not provided
    const [searchTerm, setSearchTerm] = useState("");
    const [suggestions, setSuggestions] = useState({ categories: [], products: [] });
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const wrapperRef = useRef(null);

    // Debounced function to fetch search suggestions
    const debouncedFetch = debounce(async (query) => {
        if (query.length > 2) {
            try {
                const response = await Product_services.searchAutocompleteProduct(query).then(resp => {
                    setSuggestions(resp.data);
                    setDropdownVisible(true);
                }).catch(err => {
                    // Alert.Error("Something went wrong. Please try again later.   Error: "+err.message);
                });
            } catch (error) {
                console.error("Error fetching suggestions", error);
            }
        } else {
            setSuggestions({ categories: [], products: [] });
            setDropdownVisible(false);
        }
    }, 300); // Debounce with a 300ms delay

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        debouncedFetch(value);
    };

    // Handle clicks outside the dropdown
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        // Add event listener for clicks outside the component
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Clean up the event listener on unmount
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Conditionally apply classes based on props
    const mobileClassName = isMobile ? "mobile-show" : "mobile-hide";
    const desktopClassName = isDesktop ? "desktop-show" : "desktop-hide";

    return (
        <div className={`position-relative ${mobileClassName} ${desktopClassName}`} ref={wrapperRef}>
            <input
                type="text"
                className="form-control search-input"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleChange}
                style={{ width }}  // Apply the width passed via props
                onFocus={() => setDropdownVisible(true)} // Show dropdown on input focus
            />
            <Link
                to={`/products/search?keyword=${encodeURIComponent(searchTerm)}`}
                className="btn btn-link position-absolute search-button"
                style={{ right: searchBtnRight }}  // Apply the right padding from props
            >
                <i className="fa fa-search fa-2x clr-theme-primary mobile-fa-search-ico"></i>
            </Link>
            {isDropdownVisible && (suggestions.categories.length > 0 || suggestions.products.length > 0) && (
                <ul className="autocomplete-dropdown pad-2per">
                    {suggestions.categories.length > 0 && (
                        <>
                            <li className="text-left"><strong>Categories:</strong></li>
                            {suggestions.categories.slice(0, 5).map((category, index) => (
                                <a href={`/category/${category.id}/products`} key={index}>
                                    <li className="autocomplete-item clr-black">{category.category_name.charAt(0).toUpperCase() + category.category_name.slice(1).toLowerCase()}</li>
                                </a>
                            ))}
                        </>
                    )}
                    {suggestions.sub_categories.length > 0 && (
                        <>
                            <li className="text-left"><strong>Sub Categories:</strong></li>
                            {suggestions.sub_categories.slice(0, 5).map((sub_category, index) => (
                                <a href={`/sub_category/${sub_category.id}/products`} key={index}>
                                    <li className="autocomplete-item clr-black">{sub_category.sub_category_name.charAt(0).toUpperCase() + sub_category.sub_category_name.slice(1).toLowerCase()}</li>
                                </a>
                            ))}
                        </>
                    )}
                    {suggestions.topics.length > 0 && (
                        <>
                            <li className="text-left"><strong>Topics:</strong></li>
                            {suggestions.topics.slice(0, 5).map((topic, index) => (
                                <a href={`/topic/${topic.id}/products`} key={index}>
                                    <li className="autocomplete-item clr-black">{topic.topic_name.charAt(0).toUpperCase() + topic.topic_name.slice(1).toLowerCase()}</li>
                                </a>
                            ))}
                        </>
                    )}
                    {suggestions.products.length > 0 && (
                        <>
                            <li className="text-left"><strong>Products:</strong></li>
                            {suggestions.products.slice(0, 5).map((product, index) => (
                                <a href={`/product/${product.id}/detail`} key={index}>
                                    <li className="autocomplete-item clr-black text-lowercase">
                                        <img src={product.logo} className="search-logo-width-6per"/> {product.venture_name.charAt(0).toUpperCase() + product.venture_name.slice(1).toLowerCase()}
                                    </li>
                                </a>
                            ))}
                        </>
                    )}
                </ul>
            )}
        </div>
    );
};

export default Search;
