import React, { useState } from 'react';
import styles from './SignUpSignInForm.module.css'; // Import CSS Module

const SignUpSignInForm = () => {
    const [rightPanelActive, setRightPanelActive] = useState(false);

    const handleSignUpClick = () => {
        setRightPanelActive(true);
    };

    const handleSignInClick = () => {
        setRightPanelActive(false);
    };

    return (
        <div className={`${styles.container} ${rightPanelActive ? styles.rightPanelActive : ''}`} id="container">
            <div className={`${styles.formContainer} ${styles.signUpContainer}`}>
                <form action="/user/dashboard" className="bg-black">
                    <h3 className="clr-white">Create Account</h3>
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                    <button>Sign Up</button>
                </form>
            </div>
            <div className={`${styles.formContainer} ${styles.signInContainer}`}>
                <form action="/user/dashboard" className="bg-black">
                    <h3 className="clr-white">Sign in</h3>
                    <input type="email" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                    <br/>
                    <a href="#">Forgot your password?</a>
                    <br/>
                    <button>Sign In</button>
                </form>
            </div>
            <div className={styles.overlayContainer}>
                <div className={styles.overlay}>
                    <div className={`${styles.overlayPanel} ${styles.overlayLeft}`}>
                        <h1 className="clr-white">Welcome Back!</h1>
                        <p>To keep connected with us please login with your personal info</p>
                        <button className={styles.ghost} id="signIn" onClick={handleSignInClick}>Sign In</button>
                    </div>
                    <div className={`${styles.overlayPanel} ${styles.overlayRight}`}>
                        <h1 className="clr-white">Hello, Friend!</h1>
                        <p>Enter your personal details and start your journey with us</p>
                        <button className={styles.ghost} id="signUp" onClick={handleSignUpClick}>Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpSignInForm;
