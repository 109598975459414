import React, { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import lottieAnimation from "./chatbot.json"; // Lottie animation for chatbot

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false); // State for managing sidebar open/close
    const [messages, setMessages] = useState([]); // State for messages
    const [inputMessage, setInputMessage] = useState(""); // State for input message
    const [loading, setLoading] = useState(false); // State for loading

    // Predefined questions for quick access
    const starterQuestions = [
        "Can you help me find renewable energy providers in the UAE?",
        "Which companies offer carbon footprint reduction services?",
        "I’m looking for eco-consultants to help with sustainability initiatives.",
        "How do I implement renewable energy solutions in my venture?",
    ];

    // Function to toggle chat window open/close
    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    // Function to send message to the API
    const sendMessage = async (message = inputMessage) => {
        if (!message) return; // Don't send empty messages
        setLoading(true);

        try {
            const response = await fetch(
                "https://ecosearch-chatbot.onrender.com/api/v1/prediction/0fcdd4c6-a36b-4c19-94c9-01ad342dd5cd",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer Ei-qx9d8gtGnyEQWdLk1zaX7JVQ15Pu_yerhVM2evCY",
                    },
                    body: JSON.stringify({
                        question: message,
                    }),
                }
            );
            const data = await response.json();
            // Append the new message
            setMessages([...messages, { question: message, answer: data.text }]);
        } catch (error) {
            console.error("Error fetching chatbot response:", error);
        }

        setInputMessage(""); // Clear the input field
        setLoading(false); // Stop loader
    };

    // Handle predefined question click
    const handlePredefinedQuestionClick = (question) => {
        sendMessage(question);
    };

    return (
        <>
            {/* Floating Chat Icon */}
            <div className="chatbot-icon-container">
                {/* Tooltip text */}
                <div className="chatbot-tooltip animated-tooltip">
                    Need sustainable solutions? Let EcoBuddy guide you to the right partners for your venture!
                </div>

                <div className="chatbot-icon" onClick={toggleChat}>
                    <Player
                        autoplay
                        loop
                        src={lottieAnimation}
                        style={{ height: "100px", width: "100px" }}
                    />
                </div>
            </div>

            {/* Sidebar Chat Window */}
            <div className={`chatbot-sidebar ${isOpen ? "open" : ""}`}>
                <div className="chat-header">
                    <h4 className="clr-white">Eco Buddy</h4>
                    <button className="close-chat" onClick={toggleChat}>
                        ✖
                    </button>
                </div>

                <div className="chat-body">
                    {/* Predefined Questions in Grid Layout */}
                    <div className="row">
                        {starterQuestions.map((question, index) => (
                            <div key={index} className="col-6 col-md-6 mb-3">
                                <button
                                    className="predefined-question-box clr-black"
                                    onClick={() => handlePredefinedQuestionClick(question)}
                                >
                                    {question}
                                </button>
                            </div>
                        ))}
                    </div>

                    {/* Display messages */}
                    {messages.map((msg, index) => (
                        <div key={index} className="message-container">
                            <div className="user-message">{msg.question}</div>
                            <div className="bot-message">
                                {msg.answer.split("\n").map((line, idx) => (
                                    <p key={idx}>{line}</p>
                                ))}
                            </div>
                        </div>
                    ))}

                    {/* Show loading indicator when fetching response */}
                    {loading && <div className="loading-message">Searching...</div>}
                </div>

                {/* Input for typing message */}
                <div className="chat-footer">
                    <input
                        type="text"
                        placeholder="Type your message..."
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && sendMessage()}
                    />
                    <button className="" onClick={sendMessage}>Send</button>
                </div>
            </div>
        </>
    );
};

export default Chatbot;
